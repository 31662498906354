















import { BootstrapLink } from '@movecloser/ui-core'
import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator'
import { IEventbus } from '@movecloser/front-core'

import { findFirstFocusableElement, searchParentElement } from '../../../../support/dom-utilities'
import { log } from '../../../../support'
import {
  ALL_CONTAINERS_MOUNTED_INJECTION_KEY,
  UI_CONTAINER_ID_ATTR_PREFIX,
  UI_CONTAINER_MOUNTED_EVENTBUS_EVENT_NAME
} from '../../../atoms'

import { SkiplinksModuleContainersRegistry, SkiplinksModuleContent } from '../Skiplinks.contracts'
import { TabsModuleContainersRegistry } from '../../Tabs'

/**
 * Presentational component for the `SkiplinksModuleUi`.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<SkiplinksModuleUiPresentation>({
  name: 'SkiplinksModuleUiPresentation',
  components: { BootstrapLink },
  mounted (): void {
    this.catchContainers().then((containers) => {
      this.resolvedContainers = containers
    }).catch(error => {
      log(error, 'error')
    })

    this.removeGridGap()
  }
})
export class SkiplinksModuleUiPresentation extends Vue {
  /**
   * An instance of the `EventBus` service.
   */
  @Prop({ type: Object, required: true })
  private readonly eventBus!: IEventbus

  /**
   * @see SkiplinksModuleContent.containers
   */
  @Prop({ type: Array, required: true })
  public readonly containers!: SkiplinksModuleContent['containers']

  /**
   * Determines whether all containers have been mounted yet.
   */
  @InjectReactive({ from: ALL_CONTAINERS_MOUNTED_INJECTION_KEY, default: false })
  private readonly allContainersMounted!: boolean

  /**
   * All found key-value pairs of id's with associated containers.
   */
  public resolvedContainers: SkiplinksModuleContainersRegistry | null = null

  /**
   * Only those anchors which associated containers have been successfully found in the DOM.
   *
   * @see containers
   * @see catchContainers()
   */
  public get foundContainers (): SkiplinksModuleContent['containers'] {
    if (this.resolvedContainers === null) {
      return []
    }

    return this.containers.filter(container => {
      // @ts-expect-error - TS does not recognise the above `null` check :)
      return Object.keys(this.resolvedContainers).includes(container.containerId)
    })
  }

  /**
   * Catches the HTML elements associated with the tabs' containers.
   */
  private async catchContainers (): Promise<TabsModuleContainersRegistry> {
    // We have to use the `Promise` here, as the containers are being mounted
    // in a mainly random order. In other words, there's no guarantee that in the time
    // of running this method all the containers will be mounted and their root HTML elements
    // will be present in the DOM.
    const possibleContainers: PromiseSettledResult<HTMLDivElement>[] = await Promise.allSettled(
      // Loop through all the tabs and create a new `Promise` object for each of them.
      this.containers.map<Promise<HTMLDivElement>>(({ containerId }) => {
        return new Promise<HTMLDivElement>((resolve, reject) => {
          // Try to catch the corresponding container element within the DOM.
          let container: HTMLDivElement | null = null
          const selector: string = `#${UI_CONTAINER_ID_ATTR_PREFIX}${containerId}`

          /**
           * Tries to catch the container element within the DOM.
           *
           * @see container
           */
          const catchContainer = (): void => {
            container = document.querySelector<HTMLDivElement>(selector)
          }

          catchContainer()

          // If an attempt was a success (i.e. HTML element has been successfully queried)
          // resolve the `Promise` and return the caught container element.
          if (container !== null) {
            return resolve(container)
          }

          // Else, if the `querySelector()` returned `null`,
          // start watching the event bus for the upcoming events
          // with `name` property set to `UI_CONTAINER_MOUNTED_EVENTBUS_EVENT_NAME`.
          // This kind of event is being emitted when the `<UiContainer>` component gets mounted.
          this.eventBus.handle<{ id: string }>(
            UI_CONTAINER_MOUNTED_EVENTBUS_EVENT_NAME, event => {
              // When an event is intercepted, check if the corresponding `id` payload property
              // is equal to the `containerId` variable. If true, it means that the emitted event
              // relates to the same container that the tab is assigned to.
              if (event.payload?.id === containerId) {
                // Try to catch the corresponding container element within the DOM.
                catchContainer()

                if (container === null) {
                  return reject(new Error(`Associated <UiContainer> with the ID of [${containerId}] has been mounted, but couldn't be found in DOM!`))
                }

                // If an attempt was a success (i.e. HTML element has been successfully queried)
                // resolve the `Promise` and return the caught container element.
                return resolve(container)
              }
            })

          /**
           * Rejects the promise when all containers have been successfully mounted.
           *
           * @see reject
           */
          const rejectWhenAllContainersMounted = () => {
            if (this.allContainersMounted) {
              return reject(new Error(`Associated <UiContainer> with the ID of [${containerId}] hasn't been mounted. Aborting.`))
            }

            this.$watch('allContainersMounted', rejectWhenAllContainersMounted)
          }

          return rejectWhenAllContainersMounted()
        })
      }))

    return this.containers.reduce<TabsModuleContainersRegistry>((acc, { containerId }) => {
      const foundContainers: HTMLDivElement[] = []

      possibleContainers.forEach(container => {
        if (container.status === 'fulfilled') {
          foundContainers.push(container.value)
        }
      })

      const container: HTMLDivElement | undefined =
        foundContainers.find(container => container.id.includes(containerId))

      if (typeof container === 'undefined') {
        log(`Failed to find the container with the ID of [${containerId}]!`, 'error')
        return acc
      }

      return { ...acc, [containerId]: container }
    }, {})
  }

  /**
   * Determines whether the component has been provided with the correct `containers` @Prop.
   *
   * @see containers
   */
  public get hasContainers (): boolean {
    return typeof this.containers !== 'undefined' &&
      Array.isArray(this.containers) &&
      this.containers.length > 0
  }

  /**
   * Scrolls into the container.
   */
  public scrollToContainer (e: MouseEvent, container: { containerId: string; label: string }): void {
    e.preventDefault()

    if (!this.resolvedContainers) {
      return
    }

    this.resolvedContainers[container.containerId].scrollIntoView()

    const navbar = this.resolvedContainers[container.containerId].querySelector('.NavbarDesktop')
    const footer = this.resolvedContainers[container.containerId].querySelector('.Footer')

    if (navbar) {
      const navbarEl = findFirstFocusableElement(navbar as HTMLElement)

      if (typeof navbarEl === 'undefined') {
        return
      }

      (navbarEl as HTMLElement).focus()
    } else if (footer) {
      const footerEl = findFirstFocusableElement(footer as HTMLElement)

      if (typeof footerEl === 'undefined') {
        return
      }

      (footerEl as HTMLElement).focus()
    } else {
      const main = document.getElementsByTagName('main')[0]

      if (typeof main === 'undefined') {
        return
      }
      main.setAttribute('tabindex', '-1')
      main.focus()
    }
  }

  private removeGridGap (): void {
    const parent = searchParentElement((this.$el as HTMLElement), 3)

    if (typeof parent === 'undefined') {
      return
    }

    parent.style.gridGap = '0'
  }
}

export default SkiplinksModuleUiPresentation
